a.invisible {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    pointer-events: none;
}

.link-button.footer {
    padding: 2px 6px;
}

.link-button.on-grey:hover {
    color: black;
    background-color: var(--ks-divider);
}

.link-button.on-black:hover {
    color: white;
    background-color: var(--white-divider);
}