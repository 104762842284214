.keystone-select {
    width: initial;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: white;
    border-radius: 4px;
    padding: 8px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../../media/chevron_down_light_24.png");
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 14px;
    cursor: pointer;
}

.keystone-select.on-white {
    background-color: var(--ks-divider-light);
    box-shadow: none;
    border: none;
}

.keystone-select.on-grey {
    border: 1px solid var(--ks-divider-transparent-light);
    box-shadow: var(--ks-box-shadow-shallow);
    background-color: white;
    color: black
}

.keystone-select.error {
    border-color: var(--red);
}

.keystone-select:focus, .keystone-select:hover {
    border: 1px solid var(--ks-divider-dark);
}

.keystone-select.on-grey.default {
    color: var(--ks-divider-dark);
}

@media only screen and (max-width: 767px) {
    .keystone-select {
        padding: 10px 8px;
    }
}