.keystone-icon-wrapper {
   mask-size: cover;
   -webkit-mask-size: cover;
   mask-repeat: no-repeat;
   -webkit-mask-repeat: no-repeat;
   mask-position: center;
   -webkit-mask-position: center;
   display: inline-block;
   transition: all 0.24s ease-in-out;
}

.keystone-icon-wrapper.on-grey-icon {
   background-color: var(--ks-blue-dark) !important;
}

.keystone-icon-wrapper.on-grey-icon:hover {
   background-color: black !important;
}

.keystone-icon-wrapper.on-black-icon {
   background-color: var(--white-transparent) !important;
}

.keystone-icon-wrapper.on-black-icon:hover {
   background-color: white !important;
}

@media only screen and (max-width: 767px) {
}