.text-area {
    border-radius: 4px;
    background-color: white;
    box-shadow: var(--ks-box-shadow-shallow);
    border: 1px solid var(--ks-divider);
    resize: vertical;
    min-height: 15vh;
    max-height: 45vh;
}

.text-area.error {
    border-color: var(--red);
}

.text-area:focus {
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--ks-blue-dark);
}

.text-area.large {
    padding: 10px 8px;
}

.text-area.small {
    padding: 8px 12px;
    min-height: 86px;
    max-height: 144px;
}

.text-area.on-white, .keystone-select.on-white {
    background-color: var(--ks-divider-light);
    box-shadow: none;
    border: none;
}

.text-area::-webkit-outer-spin-button,
.text-area::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-area::placeholder {
    color: var(--ks-divider-dark);
}

.text-area.align-right {
    text-align: right;
}

@media only screen and (max-width: 767px) {
}